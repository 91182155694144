/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
// import '../../TaskDescription.module.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
  CompositeFilterDescriptor,
  State,
  process as gridProcess,
} from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useEffect, useRef, useState } from 'react';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Filter } from '@progress/kendo-react-data-tools';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { FILTER_INITIAL, PAGEABLE_DATA_LOCAL } from '../../../../utils/helpers/constants';
import type {
  EnrichedTaskActionResponse,
  TaskAssetLdapSynchronizeAction,
} from '../../../../types/__generated/on-premise-solution/api/enrichedTaskActionsResponse.v1';
import { StatusCell } from './cell/StatusCell';
import { ResultCell } from './cell/ResultCell';
import { HostnameCell } from './cell/HostnameCell';
import { OcCell } from './cell/OcCell';
import { ToGrCell } from './cell/ToGrCell';
import { LDAP_SYNC_RESULT } from '../../../../utils/helpers/constant-local-filter-task';
import {
  handlerCustomSearch,
  onFilterChange,
} from '../../../../utils/helpers/local-filter-helpers';
import { Input } from '../../../common/baseElements/Input';
import { useLocalExcelExport } from '../../../../hooks/components/useLocalExcelExport';
import { Button } from '../../../common/baseElements/Button';
import { StandartCell } from '../../../common/baseElements/StandartCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;
export function ResultSyncTask({
  data,
}: {
  data: EnrichedTaskActionResponse[] | undefined;
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'hostname', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.VulnsSoft ? Number(pageLimitGrid.VulnsSoft) : 10,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const fieldFindInTable = 'hostname';

  const [filter, setFilter] = useState(FILTER_INITIAL);

  const [customSearch, setCustomSearch] = useState('');

  const [dataState, setDataState] = useState<State>(initialDataState);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _export = useRef<ExcelExport | null>(null);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _grid = useRef<React.LegacyRef<Grid> | undefined>();

  // const resultData = useRef<TaskAssetLdapSynchronizeAction['result'] | null>(null);

  const [resultData, setResultData] = useState<TaskAssetLdapSynchronizeAction['result'] | null>(
    null,
  );

  const typeOfServer = useRef<'ldap' | 'activedirectory' | undefined>();

  useEffect(() => {
    if (data) {
      const result = data[0].result as TaskAssetLdapSynchronizeAction['result'];
      setResultData(result);
      typeOfServer.current = data[0].ldap?.type;
    }
  }, [data]);

  const excelExport = useLocalExcelExport(dataState, _export, resultData, setDataState);

  const fileName = localeNewTask[currentLocale].taskSyncDescription.title;

  return (
    <>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={(event): void =>
                onFilterChange(
                  event,
                  setCustomSearch,
                  setDataState,
                  dataState,
                  setFilter,
                  filterValue,
                  customSearch,
                  fieldFindInTable,
                )
              }
              fields={LDAP_SYNC_RESULT[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={
                  localeNewTask[currentLocale].taskSyncDescription.tabs.resultPlaceHolder
                }
                value={customSearch}
                onChange={(e): void =>
                  handlerCustomSearch(
                    e,
                    setCustomSearch,
                    setDataState,
                    filterValue,
                    dataState,
                    fieldFindInTable,
                  )
                }
              />
            )}
            <Button excel fill="flat" onClick={excelExport}>
              {localeNewTask[currentLocale].taskSyncDescription.tabs.exportToExcel}
            </Button>
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <ExcelExport data={resultData || []} ref={_export} fileName={fileName}>
            <Grid
              pageable={data && data.length > 10 ? PAGEABLE_DATA_LOCAL : false}
              sortable
              data={gridProcess(resultData || [], dataState)}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent): void => {
                setDataState(e.dataState);
                if (pageLimitGrid) {
                  const pageLimit = {
                    ...pageLimitGrid,
                    VulnerabilitiesList: String(e.dataState.take),
                  };
                  localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                } else {
                  const pageLimit = {
                    VulnerabilitiesList: String(e.dataState.take),
                  };
                  localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                }
              }}
              className="taskaudit__vulnsgrid"
              scrollable="none"
              ref={_grid.current}
            >
              <Column
                field="isImported"
                title={localeNewTask[currentLocale].taskSyncDescription.tabs.status}
                cell={StatusCell}
                width="80px"
                className="title"
                filter="boolean"
              />
              <Column
                field="hostname"
                title={localeNewTask[currentLocale].taskSyncDescription.tabs.domainName}
                width="300px"
                cell={StandartCell}
                className="title"
              />
              <Column field="os.type" title="OC" cell={OcCell} />
              <Column
                field="sourceLdapGroupName"
                title={
                  typeOfServer.current === 'activedirectory'
                    ? localeNewTask[currentLocale].taskSyncDescription.tabs.fromGrAd
                    : localeNewTask[currentLocale].taskSyncDescription.tabs.fromGrLdap
                }
                cell={StandartCell}
                className="title"
              />
              <Column
                field="targetAssetGroup.name"
                title={localeNewTask[currentLocale].taskSyncDescription.tabs.toGr}
                cell={ToGrCell}
              />
              <Column
                field="summary"
                title={localeNewTask[currentLocale].taskSyncDescription.tabs.resultGr}
                cell={ResultCell}
                className="title"
              />
            </Grid>
          </ExcelExport>
        </IntlProvider>
      </LocalizationProvider>
    </>
  );
}
